@import ../../styles/helpers

.main
    height: 100%

.inner
    overflow: hidden
    padding-top: 110px
    &.overflowHidden
        overflow: unset
    +m 
        padding-top: 64px 