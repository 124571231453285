@import ../../styles/helpers

.pageLoader
  display: flex
  justify-content: center
  align-items: center 
  height: 100vh
  position: fixed
  width: 100%
  top: 0
  left: 0
  background-color: $neutrals8
  z-index: 99999999
  overflow: hidden