// common styles
:root
    scroll-behavior: smooth

*
    //scrollbar-width: thin
    // scrollbar-color: rgba(0, 0, 0, 0.3) transparent
    // +dark
    //     scrollbar-color: #B1B5C3

//Custom Scroll Bar Style for Whole Site
::-webkit-scrollbar
    background-color: transparent
    width: 6px

::-webkit-scrollbar-thumb 
    background-color: #B1B5C3
    border-radius: 8px

body
    min-width: 355px
    background: $neutrals8
    +poppins
    font-size: 16px
    line-height: 1.5
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $neutrals2
    +dark-body
        background: $neutrals1
        color: $neutrals8

a
    text-decoration: none


svg,
img
    vertical-align: middle

.desktop
    &-hide
        +d
            display: none !important
    &-show
        display: none !important
        +d
            display: block !important
    &-text-right
        +d
            text-align: right

.tablet
    &-hide
        +t
            display: none !important
    &-show
        display: none !important
        +t
            display: block !important
            
.mobile
    &-hide
        +m
            display: none !important
    &-show
        display: none !important
        +m
            display: block !important

.calendly-inline-widget
    min-height: 652px

.-dn-166
    display: none

.viewcursor
    cursor: none