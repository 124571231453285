.hero,
.h1,
.h2,
.h3,
.h4,
    +sf-pro-display
    font-weight: 700

.hero
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    +m
        font-size: 64px

.h1
    font-size: 64px
    line-height: 1
    letter-spacing: -.02em
    +d 
        font-size: 58px
    +t
        font-size: 54px
        line-height: (56/48)
    +m
        font-size: 44px


.h2
    font-size: 56px
    line-height: (56/48)
    letter-spacing: -.02em
    +l
        font-size: 52px
        line-height: (52/44)
    +t
        font-size: 48px
        line-height: (48/40)
    +s 
        font-size: 36px
        letter-spacing: 0px

.h3
    font-size: 48px
    line-height: (48/40)
    letter-spacing: -.01em
    +l
        font-size: 44px
        line-height: (44/36)
    +t
        font-size: 42px
        line-height: (40/32)
    +p 
        font-size: 36px
        line-height: (34/26)
    +m
        font-size: 24px
        line-height: (26/30)

.h4
    font-size: 36px
    line-height: (40/32)
    letter-spacing: -.01em
    +l 
        font-size: 34px
        line-height: (38/30)
    +m 
        font-size: 26px
        letter-spacing: 0px
        line-height: 1.2
    +s 
        font-size: 24px
        line-height: normal

