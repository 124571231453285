.container
  width: 100%
  //max-width: 1480px
  //padding: 0 80px
  margin: 0 auto
  padding: 0 20px
  +rmin(992)
    max-width: 960px
    padding: 0 16px
  +rmin(1200)
    max-width: 1140px
  +rmin(1400)
    max-width: 1320px
  +rmin(1600)
    max-width: 1368px
    padding: 0 24px
  // +t
  //   padding: 0 40px
  // +m
  //   padding: 0 32px
  // +s
  //   padding: 0 20px

.container-blog
  width: 100%
  max-width: 840px
  margin: 0 auto
  padding: 0 20px

.container-fluid
  width: 100%
  max-width: 100%
  