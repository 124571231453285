@import ../../styles/helpers

.mobileMenuWrap
  width: 100%
  display: none
  position: fixed
  bottom: 0px
  padding: 18px 25px
  background-color: $neutrals8
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08)
  z-index: 999
  +dark 
    background-color: $neutrals1
    box-shadow: 0px -4px 16px 0px rgba(255, 255, 255, 0.1)

  +m 
    display: block

.menuList
  display: flex
  justify-content: space-between

.activeImg
  position: absolute
  top: 0
  left: 0
  opacity: 0
  transition: all 400ms ease

.link 
  line-height: 0
  position: relative
  &.active
    .activeImg
      opacity: 1
      transition: all 400ms ease

