@import ../../styles/helpers

.customCursor
  top: 0
  left: 0
  position: fixed
  pointer-events: none
  z-index: 999
  contain: layout style size
  will-change: transform
  //transition: opacity 0.3s, color .4s
  transition: transform 0.1s ease
  &::before
    content: ""
    position: absolute
    top: -24px
    left: -24px
    display: block
    width: 48px
    height: 48px
    -webkit-transform: scale(0)
    -moz-transform: scale(0)
    -ms-transform: scale(0)
    -o-transform: scale(0)
    transform: scale(0)
    background: currentColor
    -webkit-border-radius: 50%
    -moz-border-radius: 50%
    border-radius: 50%
    -webkit-transition: opacity .1s, -webkit-transform .3s ease-in-out
    transition: opacity .1s, -webkit-transform .3s ease-in-out
    -o-transition: opacity .1s, -o-transform .3s ease-in-out
    -moz-transition: transform .3s ease-in-out, opacity .1s, -moz-transform .3s ease-in-out
    transition: transform .3s ease-in-out, opacity .1s
    transition: transform .3s ease-in-out, opacity .1s, -webkit-transform .3s ease-in-out, -moz-transform .3s ease-in-out, -o-transform .3s ease-in-out
  &.cursorVisible
    &::before
      -webkit-transform: scale(.23)
      -moz-transform: scale(.23)
      -ms-transform: scale(.23)
      -o-transform: scale(.23)
      transform: scale(.23)
      -webkit-transition-duration: 0.2s
      -moz-transition-duration: 0.2s
      -ms-transition-duration: 0.2s
      -o-transition-duration: 0.2s
      transition-duration: 0.2s 
  &.textVisible
    &::before
      opacity: 0.85
      -webkit-transform: scale(1.7)
      -moz-transform: scale(1.7)
      -ms-transform: scale(1.7)
      -o-transform: scale(1.7)
      transform: scale(1.7)
    .cursorText
      opacity: 1
      -webkit-transform: scale(1)
      -moz-transform: scale(1)
      -ms-transform: scale(1)
      -o-transform: scale(1)
      transform: scale(1)

.cursorText
  position: absolute
  top: -18px
  left: -18px
  width: 36px
  height: 36px
  display: -webkit-box
  display: -webkit-flex
  display: -moz-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -webkit-align-items: center
  -moz-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: center
  -webkit-justify-content: center
  -moz-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-transform: scale(0) rotate(10deg)
  -moz-transform: scale(0) rotate(10deg)
  -ms-transform: scale(0) rotate(10deg)
  -o-transform: scale(0) rotate(10deg)
  transform: scale(0) rotate(10deg)
  opacity: 0
  font-size: 16px
  font-weight: 500
  line-height: 20px
  text-align: center
  color: $white
  letter-spacing: -.01em
  -webkit-transition: opacity .4s, -webkit-transform .3s
  transition: opacity .4s, -webkit-transform .3s
  -o-transition: opacity .4s, -o-transform .3s
  -moz-transition: opacity .4s, transform .3s, -moz-transform .3s
  transition: opacity .4s, transform .3s
  transition: opacity .4s, transform .3s, -webkit-transform .3s, -moz-transform .3s, -o-transform .3s
  cursor: none
  +dark 
    color: $neutrals2

    