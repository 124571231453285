[class^="section"]
    padding: 136px 0
    +d
        padding: 112px 0
    +m
        padding: 64px 0

.section-md
    padding: 54px 0
    +m
        padding: 38px 0

.section-bg
    background: $neutrals7 
    +dark-common
        background: $neutrals2

.section-border-top
    border-top: 1px solid $neutrals6
    +dark-common
        border-color: $neutrals3

.section-pb
    padding-top: 0
    +d
        padding-top: 0
    +m
        padding-top: 0

.section-pb-md
    padding: 0 0 90px
    +d 
        padding: 0 0 64px
    +p 
        padding: 0 0 56px
    +m 
        padding: 0 0 38px

.section-pb64
    padding: 0 0 64px
    +d
        padding: 0 0 64px

.home-review-section
    +dark-common
        background-color: $neutrals2

.servicefeature-bg
    background: $neutrals8 
    +dark-common
        background: $neutrals2

      